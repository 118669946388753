import React, { useEffect, useState } from "react"
import { get } from "lodash"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"

import SectionHeader from "./_SectionHeader"
import Table from "./_Table"
import { formatDatetoEST } from "../../../utils/dateformat"

// import { getSubscriptionFeature as getFeature } from "../store"
// import api from "../utils/api"
// import { toastError } from "../utils/myToasts"
import { getSubscriptionFeature as getFeature } from "../../../store"
import RecurringTransactionHistoryModal from "../../../components/RecurringTransactionHistoryModal"
import { toastSuccess } from "../../../utils/myToasts"

const Container = styled.div`
  margin-bottom: 60px;
  box-sizing: border-box;

  .load-more-button {
    width: 100%;
    box-shadow: 0 0 0 1px hsla(216, 33.3%, 14.7%, 0.1),
      0 1px 3px 0 hsla(216, 12.4%, 39.4%, 0.06);
    line-height: 32px;
    white-space: pre;
    letter-spacing: 0.6px;
    text-decoration: none;
    border: 0;
    margin-top: 4px;
    position: relative;
    cursor: pointer;
    font-weight: 700;
    color: #667af4;
  }

  .gray,
  .green {
    font-weight: 700;
  }

  tbody td:nth-child(1) {
    padding-right: 14px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  .url {
    text-decoration: underline;
    cursor: pointer;
  }
`
// const formatCurr = x => Number.parseFloat(x).toFixed(2)

const CancelButton = styled.button`
  background-color: #667af4;
  font-size: 11px;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  border: 0;
  display: table;

  text-align: center;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
`
const ViewButton = styled(CancelButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px;
`

const OrderRow = props => {
  const proxy = getFeature(props, "Proxy Type")
  // const subAmount =
  //   get(props, "subscription.subscriptionPlanDetails.amount", 0) / 100
  //   const proxy = getFeature(get(props, "subscription", {}), "Proxy Type")
  //   const transactionType = get(props, "transactionType", null)
  //   const coinfiyOrderNumber = get(props, "descriptions.coinifyOrderNumber", null)
  //   const coinbaseOrderNumber = get(
  //     props,
  //     "descriptions.coinbaseOrderNumber",
  //     null
  //   )
  //   const isCredit = transactionType === "credit"
  //   const isDebit = transactionType === "debit"
  // const discount = formatCurr(subAmount - props.amount)
  const subscriptionId = get(props, "subscriptionPlanDetails.id", null)
  return (
    <tr>
      <td>
        <span className="small">{subscriptionId}</span>
      </td>
      <td align="center">
        {get(proxy, "servicesOffered", "no service found").split(",")[0]} —{" "}
        {get(proxy, "title", "no title found")}
      </td>
      <td align="right">
        {formatDatetoEST(get(props, "lastRenewedAt"), true)}
      </td>
      <td align="right">{formatDatetoEST(get(props, "autoRenewAt"), true)}</td>
      <td align="right">
        <ViewButton onClick={props.onViewAction}>
          <span>View</span>
        </ViewButton>
        {get(props, "autoRenewAt", null) && (
          <CancelButton onClick={props.onCancelAction}>Cancel</CancelButton>
        )}
      </td>
    </tr>
  )
}

export default function CryptomusRecurringList({
  recurringSubs,
  fetchCryptomusRecurringSubs,
  fetchRecurringSubsTransactionHistory,
  cancelRecurringSubscription,
  transactionHistory,
}) {
  const [openHistory, setOpenHistory] = useState(false)
  const [selectedSubId, setSelectedSubId] = useState(null)

  useEffect(() => {
    fetchCryptomusRecurringSubs()
  }, [])
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [recurringSubs])

  const handleView = id => {
    setSelectedSubId(id)
    setOpenHistory(true)
    fetchRecurringSubsTransactionHistory(id)
  }

  const onRecurringHistoryModalHide = () => {
    setSelectedSubId(null)
    setOpenHistory(false)
  }

  const handleCancel = async id => {
    try {
      const result = await cancelRecurringSubscription(id)
      if (result) {
        toastSuccess("Canceled successfully!")
        fetchCryptomusRecurringSubs()
      }
    } catch (err) {
      console.error("[Error]", err)
    }
  }

  return (
    <Container>
      <SectionHeader title="Recurring Subscriptions" />
      {recurringSubs.length === 0 ? (
        <p>You have no recurring subscriptions.</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th className="strong left">Subscription Id</th>
              <th className="strong" data-tip="Transaction date">
                Product Name
              </th>
              <th className="strong right" data-tip="Used Credit">
                Last Renewed on
              </th>
              <th className="strong right" data-tip="Used Credit">
                Upcoming Renewal Date
              </th>
              <th className="strong right" data-tip="New credit balance">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {recurringSubs.map(elem => {
              return (
                <OrderRow
                  key={elem.id}
                  {...elem}
                  onViewAction={() => handleView(elem.id)}
                  onCancelAction={() => handleCancel(elem.id)}
                />
              )
            })}
          </tbody>
        </Table>
      )}
      <RecurringTransactionHistoryModal
        isOpen={openHistory}
        transactionHistory={transactionHistory}
        onHide={onRecurringHistoryModalHide}
        selectedSubId={selectedSubId}
      />
    </Container>
  )
}
