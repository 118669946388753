import { Container } from "unstated"

import api from "../utils/api"
import deserialize from "../utils/deserialize"
import { toastError } from "../utils/myToasts"
import { get } from "lodash"

const genericError = "Oops! We couldn't process your request!"
class CryptomusRecurringSubsStore extends Container {
  constructor(props) {
    super(props)
  }

  state = {
    recurringSubs: [],
    transactionHistory: [],
  }

  fetchCryptomusRecurringSubs = async () => {
    try {
      const res = await api.get(`/cryptomus/get_recurring_subscriptions`)
      const result = await deserialize(res.data)

      this.setState({ recurringSubs: result })
    } catch (err) {
      console.error(err)
    }
  }

  fetchRecurringSubsTransactionHistory = async subscriptionId => {
    try {
      const res = await api.get(
        `/cryptomus/${subscriptionId}/transaction_history`
      )
      const result = await deserialize(res.data)
      this.setState({ transactionHistory: result })
    } catch (err) {
      const message = get(err, "response.data.errors.0.title", genericError)
      toastError(message)
      console.error(err)
    }
  }

  cancelRecurringSubscription = async subscriptionId => {
    try {
      const res = await api.delete(
        `/cryptomus/${subscriptionId}/cancel_recurring_subscription`
      )
      const result = await deserialize(res.data)
      return result
    } catch (err) {
      console.error("[Error]", err)
      const message = get(err, "response.data.errors.0.title", genericError)
      toastError(message)
    }
  }
}

export default CryptomusRecurringSubsStore
