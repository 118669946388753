import React from "react"
import styled from "styled-components"
import Modal from "./Modal"

import { MyModalBody } from "./ModalHelpers"
import Table from "../pages/settings/components/_Table"
import { get } from "lodash"
import { formatDatetoEST } from "../utils/dateformat"

const StyledModal = styled(MyModalBody)`
  .container {
    font-size: 14px;
    .header-title {
      font-weight: 600;
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
    }
    .info-message-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      margin-top: 24px;
    }
    p {
      line-height: 150%;
      margin: 0;
      text-align: justify;
    }
    .input-content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 24px;
      margin-bottom: 40px;
    }
    .input-container {
      input {
        width: 100%;
        border: 2px solid #667af4;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 20px;
        line-height: 24px;
        padding: 16px;
      }
    }
  }
`

const ModalFooter = styled.div`
  padding: 0 10%;
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
  gap: 26px;
  .button {
    width: 100%;
    text-align: center;
    border: 1px solid #c4c7cd;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #c4c7cd;
    color: #767676;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
    &:disabled {
      background: #b0b0b0;
      transform: scale(0.95);
    }
  }

  .button--close {
    // border: none !important;
    // color: rgb(102, 122, 244);
    font-weight: bold;
    background-color: #fff;
  }
  .button--continue {
    color: #fff;
    background-color: rgb(102, 122, 244);
    border: 1px solid transparent;
  }
`

const SubRow = props => {
  return (
    <tr>
      <td style={{ paddingRight: 2 }}>{get(props, "orderId", "not found")}</td>
      <td style={{ paddingLeft: 4, paddingRight: 4 }}>
        {formatDatetoEST(get(props, "createdAt"), true)}
      </td>
      <td align="right" style={{ paddingLeft: 4, paddingRight: 4 }}>
        {props.currency} {props.amount && (+props.amount).toFixed(2)}
      </td>
      <td align="right">{props.status}</td>
    </tr>
  )
}

const RecurringTransactionHistoryModal = props => {
  const { isOpen, onHide, transactionHistory } = props

  return (
    <Modal show={isOpen} onHide={onHide}>
      <StyledModal>
        <div className="container">
          <div className="header-title">Transaction History</div>
          <div className="info-message-container">
            <Table>
              <thead>
                <tr>
                  <th className="strong left">OrderId</th>
                  <th className="strong left" data-tip="Upcoming Renewal date">
                    Date
                  </th>
                  <th
                    className="strong right"
                    data-tip="Expected Credit to be applied"
                  >
                    Amount
                  </th>
                  <th
                    className="strong right"
                    data-tip="Expected Subscription Card Payment"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactionHistory.map((elem, idx) => {
                  return <SubRow key={idx} {...elem} />
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </StyledModal>
      <ModalFooter>
        <button className="button button--close cancelButton" onClick={onHide}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  )
}
export default RecurringTransactionHistoryModal
