import React from "react"

import { Subscribe } from "unstated"
import CryptomusRecurringSubsContainer from "../../stores/cryptomusRecurringSubs"

import CryptomusRecurringList from "./components/_CryptomusRecurringList"

import Layout from "./components/_Layout"

function CryptomusRecurringSubsHistory() {
  return (
    <Layout
      name="Cryptomus Recurring Subscriptions"
      title="Review your recurring subscriptions"
    >
      <Subscribe to={[CryptomusRecurringSubsContainer]}>
        {(
          crsStore //crs as cryptomus recurring store
        ) => (
          <CryptomusRecurringList
            fetchCryptomusRecurringSubs={crsStore.fetchCryptomusRecurringSubs}
            fetchRecurringSubsTransactionHistory={
              crsStore.fetchRecurringSubsTransactionHistory
            }
            cancelRecurringSubscription={crsStore.cancelRecurringSubscription}
            {...crsStore.state}
          />
        )}
      </Subscribe>
    </Layout>
  )
}

export default CryptomusRecurringSubsHistory
